<template>
    <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/6">
            <span>Customer</span>
        </div>
        <div class="vx-col vx-row sm:w-5/6">
            <div class="vx-col sm:w-5/6 w-full">
                <vs-input class="w-full" v-model="customer.Name" disabled/>
            </div>
            <div class="vx-col sm:w-1/6 w-full">
                <vs-button size="small" class="mr-3 mb-2" @click="browseCustomer()">Browse</vs-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["customer"],
        components: {
            // 
        },
        mounted(){
            // 
        },
        computed: {
            // 
        },
        watch: {
            // 
        },
        data: () => ({
            // 
        }),
        methods: {
            browseCustomer() {
                this.$emit("browseCustomer");
            },
        },
    };
</script>
