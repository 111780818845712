<template>
    <div>
        <data-table
          :responseData="dataTablePagination"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="getOptionCustomer"
        >
            <template slot="tbody">
                <vs-tr :key="index" v-for="(dt, index) in optionCustomer">                
                    <vs-td>{{dt.code}}</vs-td>
                    <vs-td>{{dt.name}}</vs-td>                                
                    <vs-td>
                        City : {{dt.city}} <br>
                        Address : {{dt.address}}
                    </vs-td>
                    <vs-td>{{dt.customer_category_name}}</vs-td>
                    <vs-td style="align-item-center">
                        <feather-icon title="Add" icon="CheckIcon" svgClasses="w-5 h-5 text-success stroke-current" class="ml-2" @click="selectCustomer(dt)"/>
                    </vs-td>
                </vs-tr>
            </template>
        </data-table>
    </div>
</template>

<script>
    export default {
        props: ["territoryId"],
        components: {
            // 
        },
        mounted(){
            // 
        },
        computed: {
            // 
        },
        watch: {
            // 
        },
        data: () => ({
            optionCustomer: [{}],

            params: {
                search: "",
                length: 10,
                page: 1,
                order: "asc",
                sort: "code"
            },

            header: [
                {
                    text: "Code", sortable: false
                },
                {
                    text: "Name", sortable: false
                },
                {
                    text: "Address", sortable: false
                },
                {
                    text: "Category", sortable: false
                },
                {
                    text: "Action", sortable: false,width: '5%'
                },
            ],

            dataTablePagination: {
                length: 0,
                recordsTotal: 0,
            },            
        }),
        methods: {
            selectCustomer(customer) {
                this.$emit("selectCustomer", customer);
            },
            getOptionCustomer(params) {
                this.params = params;
                // this.$vs.loading();
                this.$http.get("/api/v1/master/customer", {
                    params: {
                        search: params.search,
                        length: params.length,
                        page: params.page,
                        type: "End Customer",
                        territory_id: this.territoryId,
                    }
                }).then(resp => {
                    // console.log("option customer", resp);
                    if (resp.code == 200) {
                        this.optionCustomer = resp.data.records;
                        this.dataTablePagination.length = resp.data.records.length;
                        this.dataTablePagination.recordsTotal = resp.data.total_record;
                    } else {
                        this.$vs.notify({
                            title: "Error",
                            text: "Failed to get Customer",
                            color: "danger",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check"
                        });
                    }
                    // this.$vs.loading.close();
                });
            },
        },
    };
</script>