<template>
    <div>
        <div class="vx-row mb-12">
            <div v-bind:class="[detail ? detailShow : '', detailHide]">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>
                            <vs-dropdown-menu>                
                                <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                    <span>{{item}}</span>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                    <div class="mx-auto"></div>
                    <div class="vx-col sm:w-1/3 w-full">
                        <div class="flex flex-wrap items-center justify-between">
                            <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search by : Reservation Code" />
                        </div>
                    </div>
                </div>

                <vs-table :data="dataReservation" stripe border>
                    <template slot="thead">
                        <vs-th>Action</vs-th>
                        <vs-th>Reservation</vs-th>
                        <vs-th>Customer</vs-th>
                        <vs-th>Line</vs-th>
                    </template>

                    <template slot-scope="{data}">
                        <vs-tr :key="index" v-for="(dt, index) in data">
                            <vs-td>
                                <feather-icon title="Edit" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickEdit(dt)"/>
                                <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click="clickDelete(dt)"/>
                            </vs-td>
                            <vs-td>
                                Code : {{dt.Code}} <br>
                                Notes : {{dt.Notes}} <br>
                                Total Line : {{dt.ReservationLine.length}}
                            </vs-td>
                            <vs-td>
                                Category : {{dt.Customer.CustomerCategory.Name}} <br>
                                Code : {{dt.Customer.Code}} <br>
                                Name : {{dt.Customer.Name}} <br>
                            </vs-td>
                            <vs-td>
                                <span :key="indexItem" v-for="(item, indexItem) in dt.ReservationLine">
                                    {{item.ItemCode}} - {{item.ItemName}} ({{item.Quantity}} {{item.ItemUnit}}) <br>
                                </span>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
        
                <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
            </div>
        </div>
    </div>
</template>


<script>
import moment from 'moment'

export default {
    components: {
        // 
    },
    props: 
        ["territoryCode"],
        data() {
            return {
                reservationCode: "",
                dataReservation: [{
                    Customer: {
                        Code: "",
                        Name: "",
                        CustomerCategory: {
                            Name: ""
                        }
                    },
                    ReservationLine:[{}],
                }],

                limits: [10, 25, 50, 100, "All"],
                limitShow: 10,
                drawData: 0,
                isActive: 1,
                search: "",
                detailShow: "core vx-col md:w-1/2 w-full mb-base ",
                detailHide: "core vx-col md:w-1/1 w-full mb-base ",
                detail: false,
                recordsTotal: 0,
                maxPage: 2,
                dataEnd: 0,
            };
        },
    watch: {
        territoryCode: function() {
            this.getData();
        },
        salesChannel: function() {
            this.getData();
        },
        customerCategory: function() {
            this.getData();
        },
        search: function() {
            this.getData();
        }
    },
    mounted() {
        // 
    },
    computed: {
        currentPage: {
            get() {
                return 1;
            },
            set(page) {
                this.page(page);
            }
        }
    },
    methods: {
        dateFormat(date) {
            if (date) {
                return moment(String(date)).utcOffset(0).format('MM/DD/YYYY hh:mm')
            }
        },

        scrollToTop() {
            window.scrollTo(0,0);
        },

        clickEdit(reservation) {
            this.$emit("selectReservation", reservation);
            this.$emit("redirectTab", 1);
        },

        clickDelete(reservation) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "You are about to delete '"+reservation.Code+"' ?",
                accept: this.actionDelete,
                parameters: reservation
            });
        },

        actionDelete(reservation) {
            this.$vs.loading();
            this.$http
                .post("api/v1/reservation/delete", {
                    reservation_code: reservation.Code,
                })
                .then(resp => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "Reservation successfully deleted",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                this.getData();
            });
            this.$vs.loading.close();
        },

        page(page) {
            this.getData(page);
            return page;
        },

        changeLimit(limit) {
            this.limitShow = limit;
            this.getData(this.isActive);
        },

        getData(page = 1) {
            this.isActive = page;
            this.$vs.loading();

            this.$http.get("/api/v1/reservation", {
                params: {
                    territory_code: this.territoryCode,
                    approval_status: 0,
                    creator_restriction: true,
                    search: this.search,
                    limit: this.limitShow,
                    offset: this.limitShow * (page - 1)
                }
            }).then(resp => {
                if (resp.code == 200) {
                    this.drawData = this.drawData + 1;
                    this.dataReservation = resp.data.Reservations;
                    this.recordsTotal = resp.data.RecordsTotal;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.ResultsTotal;
                    this.checkedAll = false;
                    this.$vs.loading.close();
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            });
        },
    }
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>