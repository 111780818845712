<template>
    <!-- HORIZONTAL LAYOUT -->
    <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full mb-base">
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Reservation Code</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-input class="w-full" v-model="reservation.Code" disabled/>
                </div>
            </div>
            <fieldCustomer :customer="reservation.Customer" :territoryId="territoryId" v-on:browseCustomer="browseCustomer"></fieldCustomer>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Due Date</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <datepicker :inline="false" v-model="reservation.DueDate" placeholder="Select Reservation Due Date"></datepicker>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Notes</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-textarea maxlength ="255" class="w-full" name="Notes" v-model="reservation.Notes" />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Line Items</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-button size="small" class="mr-3 mb-2" @click="browseItem()">Browse</vs-button>
                </div>
            </div>
            <br>
            <hr>
            <br>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-table :data="itemsCode" stripe style="width:100%">
                        <template slot="thead">
                            <vs-th >Code</vs-th>
                            <vs-th >Name</vs-th>
                            <vs-th>Qty</vs-th>
                            <vs-th>Action</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="index" v-for="(dt, index) in data">
                                <vs-td style="align-item-center">{{itemsCode[index]}}</vs-td>
                                <vs-td style="align-item-center">{{itemsName[index]}} ({{itemsUom[index]}})</vs-td>
                                <vs-td>
                                    <vue-number-input
                                        inline
                                        controls
                                        :min="0"
                                        :step="1"
                                        size="small"
                                        v-model="itemsQuantity[index]"
                                        :value="0"
                                    />
                                </vs-td>
                                <vs-td style="align-item-center">
                                    <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 text-danger stroke-current" class="ml-2" @click="deleteItemLine(index)"/>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col sm:w-6/12 w-full" v-if="update">
                    <vs-button v-if="quantityChecked" class="mr-3 mb-2" v-on:click="SubmitForm()">Submit</vs-button>
                    <vs-button v-if="quantityChecked != true" class="mr-3 mb-2" v-on:click="CheckQty()">CheckQty</vs-button>
                    <vs-button color="danger" class="mr-3 mb-2" @click="CancelForm()">Cancel</vs-button>
                </div>
            </div>
        </div>
        <div v-if="this.optionItemAvailableShow" class="vx-col sm:w-1/2 w-full mb-base">
            <div class="vx-row mb-6">                
                <h3>Item Available</h3>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-dropdown vs-trigger-click class="cursor-pointer">
                        <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                            <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                        </div>
                        <vs-dropdown-menu>                
                            <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                <span>{{item}}</span>
                            </vs-dropdown-item>
                        </vs-dropdown-menu>
                    </vs-dropdown>
                </div>
                <div class="mx-auto"></div>
                <div class="vx-col sm:w-1/3 w-full">
                    <div class="flex flex-wrap items-center justify-between">
                        <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search by : Code, Name, or UOM " />
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-table :data="optionItemAvailable" stripe style="width:100%">
                        <template slot="thead">
                            <vs-th>Code</vs-th>
                            <vs-th>Name</vs-th>
                            <vs-th>UOM</vs-th>
                            <vs-th>Action</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="index" v-for="(dt, index) in data">
                                <vs-td>{{dt.code}}</vs-td>
                                <vs-td style="align-item-center">{{dt.name}}</vs-td>                                
                                <vs-td>({{dt.uom}})</vs-td>
                                <vs-td style="align-item-center">
                                    <feather-icon title="Add" icon="PlusIcon" svgClasses="w-5 h-5 text-success stroke-current" class="ml-2" @click="addItemLine(dt)"/>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                    <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
                </div>
            </div>
        </div>
        <div v-if="this.optionCustomerShow" class="vx-col sm:w-1/2 w-full mb-base">
            <datatableCustomer :territoryId="territoryId" v-on:selectCustomer="updateSelectedCustomer"></datatableCustomer>
        </div>
    </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import VueNumberInput from "@chenfengyuan/vue-number-input";
import fieldPromotionCode from '../component/field_promotion_code.vue'
import datatablePromotionCode from "../component/datatable_promotion_code.vue";
import fieldCustomer from '../component/field_customer.vue';
import datatableCustomer from "../component/datatable_customer.vue";
// import moment from 'moment'

export default {
    props: ["territoryCode", "territoryId", "selectedReservation", "optionTerritory"],
    components: {
        Datepicker,
        VueNumberInput,
        fieldPromotionCode,
        datatablePromotionCode,
        fieldCustomer,
        datatableCustomer
    },
    mounted(){
        // console.log("mounted - form : ", this.territoryCode, this.territoryId, this.selectedReservation, this.optionTerritory);
        this.update = true;
        if(this.selectedReservation && this.selectedReservation.Code) {
            this.resetReservation = Object.assign({}, this.selectedReservation);
            this.reservation = this.selectedReservation;

            this.reservation.Date = new Date(this.reservation.Date)
            this.reservation.DueDate = new Date(this.reservation.DueDate)

            if (this.reservation.ApprovalStatus == 1) {
                this.update = false;
            }

            for (var i = 0; i < this.selectedReservation.ReservationLine.length; i++) {
                this.itemsCode.push(this.reservation.ReservationLine[i].ItemCode);
                this.itemsName.push(this.reservation.ReservationLine[i].ItemName);
                this.itemsUom.push(this.reservation.ReservationLine[i].ItemUnit);
                this.itemsQuantity.push(this.reservation.ReservationLine[i].Quantity);
            }

            console.log(this.itemsCode);

            // this.CalculateTotal();
        } else {
            this.setDefaultDate();
        }
    },
    computed: {
        charactersLeft() {
            var char = this.reservation.Notes.length,
                limit = 255;

            return "counter " + (limit - char);
        },
        category: function() {
            return this.catsgory;
        },
        currentPage: {
            get() {
                return 1;
            },
            set(page) {
                if(this.optionItemAvailableShow) {
                    this.getOptionItemAvailable(page);
                }
                return page;
            }
        },
    },
    watch: {
        search: function() {
            if(this.optionItemAvailableShow) {
                this.getOptionItemAvailable();
            }
        },
        itemsQuantity: function() {
            this.quantityChecked = false;
        },
    },
    data: () => ({
        update: false,
        quantityChecked: false,
        external: false,

        selectTerritory:{},

        resetReservation: {
            Code: "",
            CompanyID: "",
            Company: {
                Code: "",
            },
            CustomerID: "",
            Customer: {
                Code: "",
                Name: ""
            },
            DueDate: "",
        },
        reservation: {
            Code: "",
            CompanyID: "",
            Company: {
                Code: "",
            },
            CustomerID: "",
            Customer: {
                Code: "",
                Name: ""
            },
            DueDate: "",
        },

        itemsCode: [],
        itemsName: [],
        itemsUom: [],
        itemsQuantity: [],

        optionItemAvailable: [{}],

        optionCustomerShow: false,
        optionItemAvailableShow: false,

        limits: [10, 25, 50, 100, "All"],
        limitShow: 10,
        drawData: 0,
        isActive: 1,
        search: "",
        recordsTotal: 0,
        maxPage: 2,
        dataEnd: 0,
    }),
    methods: {
        checkDate() {
            let soDate = this.reservation.Date
            let deliveryDate = this.reservation.DueDate
            if(soDate.getFullYear() == deliveryDate.getFullYear() && soDate.getMonth() == deliveryDate.getMonth() && soDate.getDate() <= deliveryDate.getDate()) {
                return true;
            } else {
                return false;
            }
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
        containsKey(obj, key ) {
            return Object.keys(obj).includes(key);
        },
        setDefaultDate() {
            var day = new Date();
            var nextWeek = new Date(day);
            nextWeek.setDate(day.getDate() + 7); 

            // this.reservation.DueDate = day // ini kalau default == hari ini
            this.reservation.DueDate = nextWeek
        },
        CancelForm() {
            this.update = true;
            this.quantityChecked = false;
            this.external = false;

            this.selectTerritory = {};

            this.resetReservation = {
                Code: "",
                CompanyID: "",
                Company: {
                    Code: "",
                },
                CustomerID: "",
                Customer: {
                    Code: "",
                    Name: ""
                },
                DueDate: "",
            };
            this.reservation = this.resetReservation;

            this.itemsCode = [];
            this.itemsName = [];
            this.itemsUom = [];
            this.itemsQuantity = [];

            this.optionItemAvailable = [{}];

            this.optionItemAvailableShow = false;
            
            this.$emit("selectReservation", {});
        },
        browseItem() {
            this.search = "";
            this.optionItemAvailableShow = true;
            this.optionCustomerShow = false;
            this.getOptionItemAvailable();
            this.scrollToTop();
        },
        browseCustomer() {
            this.search = "";
            this.optionCustomerShow = true;
            this.optionItemAvailableShow = false;
            this.getOptionItemAvailable();
            this.scrollToTop();
        },
        addItemLine(item) {
            this.quantityChecked = false;
            if(this.external == false) {
                this.itemsCode.push(item.code);
                this.itemsName.push(item.name);
                this.itemsUom.push(item.uom);
                this.itemsQuantity.push(0);
            } else {
                this.$vs.notify({
                    title: "Warning",
                    text: "You're not allowed to add or remove external Reservation item line ",
                    color: "warning",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
        },
        deleteItemLine(index) {
            this.quantityChecked = false;
            if(this.external == false) {
                this.itemsCode.splice(index, 1);
                this.itemsName.splice(index, 1);
                this.itemsUom.splice(index, 1);
                this.itemsQuantity.splice(index, 1);
            } else {
                this.$vs.notify({
                    title: "Warning",
                    text: "You're not allowed to add or remove external Reservation item line",
                    color: "warning",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
        },
        changeLimit(limit) {
            this.limitShow = limit;
            if(this.optionItemAvailableShow) {
                this.getOptionItemAvailable(this.isActive);
            }
        },
        getOptionItemAvailable(page = 1) {
            this.isActive = page;
            this.$vs.loading();

            this.$http.get("/api/v1/item-available", {
                params: {
                    search: this.search,
                    limit: this.limitShow,
                    offset: this.limitShow * (page - 1)
                }
            }).then(resp => {
                // console.log(resp.data);
                if (resp.code == 200) {
                    this.optionItemAvailable = resp.data.ItemsAvailable;
                    this.recordsTotal = resp.data.RecordsTotal;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.ResultsTotal;
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Item Available",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        updateSelectedCustomer(customer) {
            this.quantityChecked = false;
            this.reservation.Customer.Code = customer.code;
            this.reservation.Customer.Name = customer.name;
            this.reservation.CustomerID = customer.id;
        },
        CheckQty() {
            
            if(this.reservation.Customer.Code == "" || this.reservation.Customer.Code == 0 || this.reservation.Customer.Code == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Customer first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.reservation.DueDate == "" || this.reservation.DueDate == 0 || this.reservation.DueDate == null || this.reservation.DueDate == "0001-01-01T00:00:00Z" ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Due Date first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(!this.checkDate()) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Delivery Date must be equal or after Sales Order Date",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.itemsCode.length == 0) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Item Line first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            this.search = "";
            this.optionCustomerShow = false;
            this.optionItemAvailableShow = false;
            // this.$vs.loading();
            this.$http
            .post("/api/v1/item-available/check-Availability", {
                reservation_code: this.reservation.Code,
                territory_code: this.territoryCode,
                customer_code: this.reservation.Customer.Code,
                notes: this.reservation.Notes,
                due_date: this.reservation.DueDate,
                items_code: this.itemsCode,
                items_name: this.itemsName,
                items_uom: this.itemsUom,
                items_quantity: this.itemsQuantity,
            })
            .then(resp => {
                // console.log(resp);
                if (resp.code == 200) {
                    this.$vs.notify({
                        title: "Success",
                        text: "",
                        color: "success",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                    // console.log(resp);
                    // items_code: this.itemsCode,
                    // items_name: this.itemsName,
                    // items_uom: this.itemsUom,
                    // items_quantity: this.itemsQuantity,

                    var check = true;
                    var checkQuantityItems = resp.data.items
                    for (var i = 0; i < checkQuantityItems.length; i++) {
                        if (checkQuantityItems[i].qty_req > checkQuantityItems[i].qty_adj) {
                            var newValue = 1;
                            this.itemsQuantity.splice(i, 1, newValue);
                            this.$vs.notify({
                                title: "Error",
                                text: "Item "+checkQuantityItems[i].item_code+" exceed max quantity ("+checkQuantityItems[i].qty_adj+")<br>Change it to 1",
                                color: "danger",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-check"
                            });
                            check = false;
                        }
                    }
                    this.quantityChecked = check;
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to check quantity",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                    this.quantityChecked = false;
                }
                console.log(this.itemsQuantity);
                // this.$vs.loading.close();
            });
        },
        SubmitForm() {
            if(this.reservation.Customer.Code == "" || this.reservation.Customer.Code == 0 || this.reservation.Customer.Code == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Customer first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.reservation.DueDate == "" || this.reservation.DueDate == 0 || this.reservation.DueDate == null || this.reservation.DueDate == "0001-01-01T00:00:00Z" ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Due Date first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(!this.checkDate()) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Delivery Date must be equal or after Sales Order Date",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.itemsCode.length == 0) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Item Line first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            // this.$vs.loading();
            this.$http
            .post("/api/v1/reservation/store", {
                reservation_code: this.reservation.Code,
                territory_code: this.territoryCode,
                customer_code: this.reservation.Customer.Code,
                notes: this.reservation.Notes,
                due_date: this.reservation.DueDate,
                items_code: this.itemsCode,
                items_name: this.itemsName,
                items_uom: this.itemsUom,
                items_quantity: this.itemsQuantity,
            })
            .then(resp => {
                // console.log(resp);
                if (resp.code == 200) {
                    this.$vs.notify({
                        title: "Success",
                        text: "Reservation has been added",
                        color: "success",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                    this.CancelForm();
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: resp.message,
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                // this.$vs.loading.close();
            });
        },
    },
};
</script>
