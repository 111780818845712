<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card title="Reservation">
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Territory</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="territory"
                        :options="optionTerritory"
                        :multiple="false"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="code"
                        label="Name"
                    >

                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <hr>
            <vs-tabs :color="colorx" ref="tabs">
                <vs-tab @click="changeTab(0)" label="New Reservation">
                    <div class="con-tab-ejemplo">
                        <br>
                        <listReservation :territoryCode="territory.code" v-on:selectReservation="updateSelectedReservation" v-on:redirectTab="redirectTab"></listReservation>
                    </div>
                </vs-tab>
                <vs-tab @click="changeTab(1)" label="Form">
                    <div class="con-tab-ejemplo">
                        <br>
                        <formReservation :territoryCode="territory.code" :territoryId="territory.id" :selectedReservation="selectedReservation" :optionTerritory="optionTerritory" v-on:selectReservation="updateSelectedReservation"></formReservation>
                    </div>
                </vs-tab>
                <!-- <vs-tab @click="changeTab(2)" label="Approved">
                    <div class="con-tab-ejemplo">
                        <br>
                        <listApprovedReservation :territoryCode="territory.code" v-on:selectReservation="updateSelectedReservation" v-on:redirectTab="redirectTab"></listApprovedReservation>
                    </div>
                </vs-tab> -->
            </vs-tabs>
        </vx-card>
    </div>
</template>

<script>
import listReservation from "./list/datatable.vue";
import formReservation from "./form/form.vue";
// import listApprovedReservation from "./approved/datatable.vue";

export default {
    components: {
        listReservation,
        formReservation
    },
    mounted() {
        this.getOptionTerritory();
    },
    data: () => ({
        tabColor:['success', 'primary', 'rgb(62, 201, 214)'],
        tabIndex: 0,
        territory: {},
        optionTerritory: [{}],
        salesChannel: {},
        optionSalesChannel:[{}],
        customerCategory: {},
        optionCustomerCategory:[{}],
        colorx: "success",
        selectedReservation: {}
    }),
    watch: {
        // territory: function(val) {
        //     console.log(val.id);
        //     console.log(val.code);
        // }
    },
    methods: {
        changeTab(index) {
            // console.log("before", this.tabIndex);
            // console.log("after", index);
            this.tabIndex = index;
            this.colorx = this.tabColor[index];
        },
        getOptionTerritory() {
            this.$http.get("/api/v1/allocation-engine/list/territory").then(resp => {
                this.optionTerritory = resp.data.territory;
                this.territory = resp.data.territory[0];
            });
        },
        updateSelectedReservation(reservation) {
            this.selectedReservation = reservation;
            this.changeTab(1);
        },
        redirectTab(index) {
            this.$refs.tabs.activeChild(index);
        }
    }
};
</script>

<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>